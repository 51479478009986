body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: #282c34;
}

.App-logo {
  height: 20vmin;
}

.App-header {
  height: 70px;
  background-color: #0080b5;
}

.booking-step {
  height: 50px;
  background-color: #fff;
}

.body {
  background-image: linear-gradient(to bottom right, #005e82, white);
}

.customize-title {
  color: #ffff;
  font-family: sans-serif;
  text-align: center;
}

.container {
  height: auto;
  border-bottom: 1px solid darkgrey;
}

.container,
#recoglobick-activities,
#recoglobick-activities-mmb,
#recoglobick-transfers,
#recoglobick-transfers-mmb {
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto;
}

.container { /* Safari/Chrome, other WebKit */ /* Firefox, other Gecko */
  box-sizing: border-box;
}

@media (min-width: 991.9808px) {
  .container,
  #recoglobick-activities,
  #recoglobick-activities-mmb,
  #recoglobick-transfers,
  #recoglobick-transfers-mmb {
    width: 70%;
  }
}

.form {
  display: flex;
  flex-direction: column;
}

.Main-App-Container {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
}

.update-btn {
  border: none;
  color: white;
  font-family: "Helvetica";
  background: #005e82;
  padding: 0.5rem;
  outline: none;
  margin: 1rem;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

